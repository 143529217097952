<template>
  <div
    v-if="isShow"
    class="helper-modal"
    :style="{ bottom: bottom + 'px', right: right + 'px' }"
  >
    <div class="header">
      <div class="title">
        <div class="icon">
          <a-icon type="question-circle" />
        </div>
        <strong>{{ lbl['helper-connect-line-title'] }}</strong>
      </div>
      <a-icon type="close" class="close" @click="closeHelper" />
    </div>
    <div class="desc">
      <span>
        {{ lbl['helper-connect-line-desc'] }}
      </span>
    </div>
    <div class="content">
      <strong>{{ lbl['helper-connect-line-contect-via'] }}</strong>
      <img src="@/assets/images/line_qrcode_bzbs.png" alt="Line qrcode Bzbs" />
      <div class="guild-section">
        <span>
          {{ lbl['helper-connect-line-guild'] }}
        </span>
        <div class="line-name">{{ lbl['about-contactus-line'] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'

export default {
  name: 'HelperBox',
  mixins: [Mixin],
  data() {
    return {
      scTimer: 0,
      scY: 0,
    }
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: [String, Number],
      default: '144',
    },
    right: {
      type: [String, Number],
      default: '82',
    },
  },
  methods: {
    closeHelper() {
      this.$emit('close', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.helper-modal {
  width: 40%;
  position: fixed;
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #e3e3e3;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .title {
      display: flex;
      align-items: center;
      gap: 16px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 4px solid #fff6e6;
        background: #ffe5b3;
        width: 40px;
        height: 40px;
        color: #f9a601;
        font-size: 18px;
      }
      strong {
        font-size: 16px;
      }
    }
    .close {
      font-size: 16px;
      color: #969696;
    }
  }
  .desc {
    color: #636363;
  }
  .content {
    text-align: center;
    border-radius: 12px;
    border: 1px solid #e0e0e0;
    background: #fafafa;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .guild-section {
      display: flex;
      flex-direction: column;
      gap: 6px;
      span {
        color: #636363;
      }
      .line-name {
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        width: 100%;
        border-radius: 6px;
        background: #4caf50;
      }
    }
  }
}
</style>
