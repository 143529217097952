<template>
  <div
    class="go-to-top-container"
    :style="{ bottom: bottom + 'px', right: right + 'px' }"
  >
    <div id="go_to_top_button" class="go-top-btn" @click="clickTop()">
      <img src="@/assets/images/arrow-to-up.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoTopButton',
  data() {
    return {
      scTimer: 0,
      scY: 0,
    }
  },
  props: {
    bottom: {
      type: [String, Number],
      default: '40',
    },
    right: {
      type: [String, Number],
      default: '16',
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    clickTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    handleScroll() {
      if (this.scTimer) return
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY
        clearTimeout(this.scTimer)
        this.scTimer = 0
      }, 100)
    },
  },
}
</script>

<style lang="scss" scoped>
.go-to-top-container {
  position: fixed;
  .go-top-btn {
    padding: 8px 16px;
    border-radius: 6px;
    background-color: rgba(33, 33, 33, 0.5);
  }
}
</style>
