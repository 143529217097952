<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto content-inner-container">
        <div class="wrap-AccountSetup">
          <div class="row paj-custom-row">
            <div class="col-12 custom-wrapper-dashboard">
              <div class="d-flex align-items-center flex-wrap mr-2 text-title">
                <img
                  src="@/assets/images/accountsetup/setting.svg"
                  alt=""
                  class="mr-2"
                />
                {{ lbl['main-menu-setting'] }}
              </div>
            </div>

            <div class="col-xl-12 col-lg-12 col-md-12 wrapper-content-tab">
              <div class="d-flex wrapper-select-header">
                <div
                  class="select-header cursor-pointer"
                  :class="{ active: false }"
                  @click="goto('AccountSetting')"
                >
                  {{ lbl['account-setup-account-setting'] }}
                </div>
                <div
                  class="select-header select-hd-right cursor-pointer"
                  :class="{ active: false }"
                  @click="goto('BranchSetting')"
                >
                  {{ lbl['account-setup-hq-and-branch'] }}
                </div>
                <div
                  v-if="isUseFacebook && checkLoginType('facebook')"
                  class="select-header select-hd-right cursor-pointer"
                  :class="{ active: false }"
                  @click="goto('ConnectFacebook')"
                >
                  {{ lbl['link-setup-tab-connect-facebook'] }}
                </div>
                <div
                  class="select-header select-hd-right cursor-pointer"
                  :class="{ active: true }"
                >
                  {{ lbl['account-setup-line-connect'] }} /
                  {{ lbl['account-setup-link-setup'] }}
                  <div v-if="setupNotification" class="dot-notification">
                    <img
                      src="@/assets/images/item-inactive-icon.png"
                      alt=""
                      class="item-status-icon ml-0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="px-5">
            <div v-if="loginType">
              <div
                v-if="
                  checkLoginType('email') ||
                  (checkLoginType('device') && !checkLoginType('line')) ||
                  checkLoginType('facebook')
                "
                class="wrap-linkSetup"
              >
                <div class="row">
                  <div class="col-12 page-header pl-4">
                    {{ lbl['main-menu-link-setup'] }}
                  </div>
                  <a-form class="col-12">
                    <div class="row">
                      <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
                        <div class="row">
                          <div
                            class="
                              col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12
                            "
                          >
                            <div class="row pt-3">
                              <div class="col-md-12 pl-4">
                                <div class="select-link mb-3">
                                  {{ lbl['link-setup-select-link-from'] }}
                                </div>
                                <span class="pl-2 pr-5"
                                  ><a-radio-group
                                    v-model="radio"
                                    class="wrapper-radio-custom"
                                    @change="changeRadio"
                                  >
                                    <a-radio class="pr-5 radio-main" :value="1">
                                      {{ lbl['line-channel-connection-page'] }}
                                    </a-radio>
                                    <a-radio
                                      class="pr-5 radio-main"
                                      :value="2"
                                      >{{
                                        lbl['line-channel-connection-campaign']
                                      }}</a-radio
                                    >
                                  </a-radio-group></span
                                >
                              </div>
                            </div>

                            <div class="row px-2 py-2 pt-3">
                              <div class="col-md-12">
                                <a-form-item class="field-item">
                                  <!-- Page -->
                                  <a-select
                                    v-decorator="[
                                      'connectionPage',
                                      {
                                        initialValue: this.page,
                                        rules: [
                                          {
                                            required: true,
                                            message: '',
                                          },
                                        ],
                                      },
                                    ]"
                                    :class="{ 'd-none': isPage }"
                                    size="large"
                                    :placeholder="
                                      this.lbl[
                                        'miscellaneous-please-select-option'
                                      ]
                                    "
                                    @change="
                                      e => {
                                        changePage(e)
                                      }
                                    "
                                  >
                                    <a-select-option
                                      v-for="(item, index) in connection.page"
                                      :key="index"
                                      :value="item.id"
                                    >
                                      {{ item.name }}
                                    </a-select-option>
                                    {{ connection.page }}
                                  </a-select>

                                  <!-- Campaign -->
                                  <a-select
                                    v-decorator="[
                                      'connectionCampaign',
                                      {
                                        initialValue: this.campaign,
                                        rules: [
                                          {
                                            required: true,
                                            message: '',
                                          },
                                        ],
                                      },
                                    ]"
                                    :class="{ 'd-none': isCampaign }"
                                    size="large"
                                    :placeholder="
                                      this.lbl[
                                        'miscellaneous-please-select-option'
                                      ]
                                    "
                                    @change="
                                      e => {
                                        changeCampaign(e)
                                      }
                                    "
                                  >
                                    <a-select-option
                                      v-for="(
                                        item, index
                                      ) in connection.campaign"
                                      :key="index"
                                      :value="item.id"
                                    >
                                      {{ item.name }}
                                    </a-select-option>
                                    {{ connection.campaign }}
                                  </a-select>
                                </a-form-item>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4 mb-5">
                      <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
                        <div class="page-languages">
                          <img
                            v-if="!isPhilipines"
                            :src="require('@/assets/images/flag-th.png')"
                            alt=""
                            class="flag-languages"
                          />
                          <img
                            v-if="isPhilipines"
                            :src="require('@/assets/images/flag-ph.png')"
                            alt=""
                            class="flag-languages"
                          />
                          {{ lbl['link-setup-link-as-thai'] }}
                        </div>
                        <div class="input-group text-center mt-3">
                          <input
                            class="form-control text-center"
                            id="LinkUrlTh"
                            name="LinkUrlTh"
                            readonly="readonly"
                            type="text"
                            :value="urlLinkTh"
                            aria-invalid="false"
                          />
                          <div class="input-group-append">
                            <button
                              class="input-group-text copy-web-hook-url"
                              data-toggle="tooltip"
                              title=""
                              data-clipboard-target="#LinkUrlTh"
                              @click="copyClipboardTh"
                              @mouseover="showTooltipTh"
                            >
                              <a-tooltip
                                slot="suffix"
                                id="tooltipClipboard"
                                :title="titleTooltipTh"
                              >
                                <img
                                  :src="
                                    require('@/assets/images/content_copy.svg')
                                  "
                                  alt=""
                                  class="content-copy"
                                />
                                {{ lbl['line-channel-connect-copy-button'] }}
                              </a-tooltip>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
                        <div class="page-languages">
                          <img
                            :src="require('@/assets/images/flag-en.png')"
                            class="flag-languages"
                            alt=""
                          />
                          {{ lbl['link-setup-link-as-english'] }}
                        </div>
                        <div class="input-group text-center mt-3">
                          <input
                            class="form-control text-center"
                            id="LinkUrlEn"
                            name="LinkUrlEn"
                            readonly="readonly"
                            type="text"
                            :value="urlLinkEn"
                            aria-invalid="false"
                          />
                          <div class="input-group-append">
                            <button
                              class="input-group-text copy-web-hook-url"
                              data-toggle="tooltip"
                              title=""
                              data-clipboard-target="#LinkUrlEn"
                              @click="copyClipboardEn"
                              @mouseover="showTooltipEn"
                            >
                              <a-tooltip
                                slot="suffix"
                                id="tooltipClipboard"
                                :title="titleTooltipEn"
                              >
                                <img
                                  :src="
                                    require('@/assets/images/content_copy.svg')
                                  "
                                  alt=""
                                  class="content-copy"
                                />
                                {{ lbl['line-channel-connect-copy-button'] }}
                              </a-tooltip>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a-form>
                </div>
              </div>
              <div v-if="checkLoginType('line')" class="wrap-connectLine">
                <!-- AlertSuccessLine -->
                <b-alert
                  variant="success"
                  dismissible
                  fade
                  :show="showAlertSuccessLine"
                  @dismissed="showAlertSuccessLine = false"
                >
                  {{ lbl['line-channel-connect-success-message'] }}
                </b-alert>

                <!-- AlertErrorLine -->
                <b-alert
                  variant="danger"
                  dismissible
                  fade
                  :show="showAlertErrorLine"
                  @dismissed="showAlertErrorLine = false"
                >
                  {{ lbl['line-channel-connect-error-message'] }}
                </b-alert>

                <!-- AlertSuccess -->
                <b-alert
                  variant="success"
                  dismissible
                  fade
                  :show="showAlertSuccess"
                  @dismissed="showAlertSuccess = false"
                >
                  {{
                    lbl[
                      'line-channel-connect-regenerate-liff-url-success-message'
                    ]
                  }}
                </b-alert>

                <!-- AlertError -->
                <b-alert
                  variant="danger"
                  dismissible
                  fade
                  :show="showAlertError"
                  @dismissed="showAlertError = false"
                >
                  {{ lbl['Line Liff has already generated.'] }}
                </b-alert>

                <div class="box">
                  <div class="topic mb-1">
                    <span class="name">{{
                      lbl['account-setup-connect-line-input-topic']
                    }}</span>
                    <span class="desc">{{
                      lbl['account-setup-connect-line-input-topic-desc']
                    }}</span>
                  </div>
                  <div class="can-not-edit">
                    *{{ lbl['if-you-change-line-provider'] }}
                  </div>
                  <div class="body">
                    <div class="row mb-1">
                      <div class="col-12 text-right">
                        <a-button
                          shape="round"
                          @click="handleGenerateLiffUrl"
                          >{{
                            lbl['line-channel-connect-regenerate-liff-url']
                          }}</a-button
                        >
                      </div>
                    </div>
                    <div
                      v-for="(item, index) in lineConnectArr"
                      :key="item.id + '_container'"
                      class="input-section"
                    >
                      <div class="name">
                        {{ item.title }}
                      </div>
                      <div class="input-container-wrapper">
                        <div class="input-container first">
                          <div class="detail-name">
                            <div class="number">{{ index + index + 1 }}</div>
                            <div class="title">
                              {{
                                lbl[
                                  'account-setup-connect-line-input-channel-id'
                                ]
                              }}
                            </div>
                          </div>
                          <div class="detail-input">
                            <a-input
                              :id="item.id + '_channel_id_input'"
                              v-model="item.channelId"
                              placeholder="Required"
                            />
                          </div>
                        </div>
                        <div class="input-container second">
                          <div class="detail-name">
                            <div class="number">{{ index + index + 2 }}</div>
                            <div class="title">
                              {{
                                lbl[
                                  'account-setup-connect-line-input-channel-secret'
                                ]
                              }}
                            </div>
                          </div>
                          <div class="detail-input">
                            <a-input
                              :id="item.id + '_channel_secret_input'"
                              v-model="item.channelSecret"
                              placeholder="Required"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="button-secction">
                      <a-button
                        id="line_channel_connect_connect_button"
                        type="primary"
                        block
                        class="button"
                        :class="{
                          'connect-success':
                            showAlertSuccessLine || isLineConnected,
                        }"
                        :disabled="!fillLineChannel"
                        @click="handleSubmit()"
                      >
                        <img
                          v-if="showAlertSuccessLine || isLineConnected"
                          :src="require('@/assets/images/linked.svg')"
                          alt=""
                        />
                        {{
                          showAlertSuccessLine || isLineConnected
                            ? lbl['line-channel-connect-success-button']
                            : lbl['line-channel-connect-connect-button']
                        }}
                      </a-button>
                    </div>
                  </div>
                </div>

                <!--How to connect line-->
                <div id="how_to_connect_line_accordion" class="box accordion">
                  <div
                    class="topic accordion-header cursor-pointer"
                    :style="{
                      'margin-bottom': isShowHowConnect ? '16px' : '0',
                    }"
                    @click="toggleHowConnect()"
                  >
                    <span class="name">
                      <img src="@/assets/images/Video.svg" alt="Video Icon" />
                      {{ lbl['how-to-connect-line-topic'] }}
                    </span>
                    <img
                      class="arrow"
                      :class="{ active: isShowHowConnect }"
                      src="@/assets/images/icon-accordion-arrow-down.svg"
                      alt=""
                    />
                  </div>
                  <div
                    class="body accordion-body"
                    :class="{
                      'how-connect-accordion-active': isShowHowConnect,
                    }"
                  >
                    <div>
                      {{ lbl['you-can'] }}
                      <a
                        class="website"
                        :href="linkVideoLine"
                        target="_blank"
                        >{{ lbl['click'] }}</a
                      >
                      {{ lbl['to-watch-the-video-or-picture'] }}
                    </div>
                    <template>
                      <a-skeleton :loading="!isshowpdfHowConnect" />
                      <a-skeleton :loading="!isshowpdfHowConnect" />
                      <a-skeleton :loading="!isshowpdfHowConnect" />
                      <a-skeleton :loading="!isshowpdfHowConnect" />
                    </template>
                    <pdf
                      v-for="i in numPagesHowConnect"
                      :key="i"
                      :v-if="isshowpdfHowConnect"
                      :src="srcHowConnect"
                      :page="i"
                      style="display: inline-block; width: 100%"
                    />
                  </div>
                </div>

                <!--How to create line-->
                <div id="how_to_create_line_accordion" class="box accordion">
                  <div
                    class="topic accordion-header cursor-pointer"
                    :style="{ 'margin-bottom': isShowHowCreate ? '16px' : '0' }"
                    @click="toggleHowCreate()"
                  >
                    <span class="name">{{
                      lbl['how-to-create-line-topic']
                    }}</span>
                    <img
                      class="arrow"
                      :class="{ active: isShowHowCreate }"
                      src="@/assets/images/icon-accordion-arrow-down.svg"
                      alt=""
                    />
                  </div>
                  <div
                    class="body accordion-body"
                    :class="{ 'how-create-accordion-active': isShowHowCreate }"
                  >
                    <template>
                      <a-skeleton :loading="!isshowpdfHowCreate" />
                      <a-skeleton :loading="!isshowpdfHowCreate" />
                      <a-skeleton :loading="!isshowpdfHowCreate" />
                      <a-skeleton :loading="!isshowpdfHowCreate" />
                    </template>
                    <pdf
                      v-for="i in numPagesHowCreate"
                      :key="i"
                      :v-if="isshowpdfHowCreate"
                      :src="srcHowCreate"
                      :page="i"
                      style="display: inline-block; width: 100%"
                    />
                  </div>
                </div>
                <GoTopButtton :bottom="120" :right="24" />

                <div class="helper-btn" @click="isHelper = !isHelper">
                  <a-icon type="question-circle" />
                </div>
                <HelperBox
                  :is-show="isHelper"
                  @close="isHelper = $event"
                  :bottom="60"
                  :right="90"
                />
                <b-modal
                  ref="my-modal-confirm"
                  modal-class="modal-Confirm"
                  :modalClass="[currentLocale]"
                  centered
                  hide-header
                  hide-footer
                  no-close-on-backdrop
                  no-close-on-esc
                >
                  <div class="modal-base">
                    <div class="row mt-3">
                      <div
                        class="col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10"
                      >
                        <div class="modal-base-head">
                          <img
                            src="@/assets/images/Clipboard-check.svg"
                            alt=""
                          />
                          {{
                            lbl[
                              'line-channel-connect-regenerate-liff-confirm-title'
                            ]
                          }}
                        </div>
                        <div class="modal-base-body mt-4">
                          {{ lbl['line-channel-connect-line-confirm-message'] }}
                        </div>
                        <div class="modal-base-body mt-2 text-danger">
                          *{{
                            lbl[
                              'line-channel-connect-regenerate-liff-confirm-message2'
                            ]
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="modal-base-footer mt-4">
                      <button
                        class="btn btn-warning ml-2"
                        @click="callApiConnectLine"
                      >
                        {{
                          lbl[
                            'line-channel-connect-regenerate-liff-confirm-title'
                          ]
                        }}
                      </button>
                      <button
                        class="btn btn-warning ml-2 cancel"
                        @click="hideModal"
                      >
                        {{
                          lbl[
                            'line-channel-connect-regenerate-liff-cancel-title'
                          ]
                        }}
                      </button>
                    </div>
                  </div>
                </b-modal>
                <b-modal
                  ref="my-modal-confirm-generate-liff"
                  modal-class="modal-Confirm"
                  :modalClass="[currentLocale]"
                  centered
                  hide-header
                  hide-footer
                  no-close-on-backdrop
                  no-close-on-esc
                >
                  <div class="modal-base">
                    <div class="row mt-3">
                      <div
                        class="col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10"
                      >
                        <div class="modal-base-head">
                          <span class="icoTitle"
                            ><img
                              src="@/assets/images/Clipboard-check.svg"
                              alt=""
                          /></span>
                          {{
                            lbl[
                              'line-channel-connect-regenerate-liff-confirm-title'
                            ]
                          }}
                        </div>
                        <div class="modal-base-body mt-4">
                          {{
                            lbl[
                              'line-channel-connect-regenerate-liff-confirm-message'
                            ]
                          }}
                        </div>
                        <div class="modal-base-body mt-2 text-danger">
                          *{{
                            lbl[
                              'line-channel-connect-regenerate-liff-confirm-message2'
                            ]
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="modal-base-footer mt-4">
                      <button
                        class="btn btn-warning ml-2"
                        @click="callApiGenerateLiffUrl"
                      >
                        {{
                          lbl[
                            'line-channel-connect-regenerate-liff-confirm-title'
                          ]
                        }}
                      </button>
                      <button
                        class="btn btn-warning ml-2 cancel"
                        @click="hideModalGenerateLiffUrl"
                      >
                        {{
                          lbl[
                            'line-channel-connect-regenerate-liff-cancel-title'
                          ]
                        }}
                      </button>
                    </div>
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Account from '@/helper/AccountHelper.js'
import Vue from 'vue'
import Mixin from '@/mixin/Mixin'
import Cache from '@/helper/Cache.js'
import Header from '@/components/Layout/Header'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import BzbsSettingsLine from '@/core/Settings/Line/BzbsSettingsLine'
import Locale from '@/helper/locale.js'
import pdf from 'vue-pdf'
import GoTopButtton from '@/components/Layout/GoTopButtton'
import HelperBox from '@/components/Layout/HelperBox'

export default {
  name: 'AccountSetting',
  mixins: [Mixin],
  components: {
    Header,
    pdf,
    GoTopButtton,
    HelperBox,
  },
  data: function () {
    return {
      currentLocale: Locale.getLocaleShort(),
      isEdit: false,
      loginType: null,
      radio: 1,
      page: '',
      campaign: '',
      isPage: false,
      isCampaign: true,
      connection: {
        page: [],
        campaign: [],
      },
      isHelper: false,
      urlLinkTh: '',
      titleTooltipTh: '',
      urlLinkEn: '',
      titleTooltipEn: '',
      host: window.location.origin,
      isPhilipines: false,
      line_message_channel_id: '',
      line_message_channel_secret: '',
      line_login_channel_id: '',
      line_login_channel_secret: '',
      titleTooltip: '',
      disabledsubmit: true,
      urlWebhook: '',
      showAlertSuccess: false,
      showAlertError: false,
      showAlertSuccessLine: false,
      showAlertErrorLine: false,
      numPagesHowCreate: undefined,
      numPagesHowConnect: undefined,
      isshowpdfHowCreate: false,
      isshowpdfHowConnect: false,
      isShowHowCreate: false,
      isShowHowConnect: false,
      howCreatePDF: null,
      howConnectPDF: null,
      appId: null,
      business_name: null,
      Email: null,
      LogoUrl: null,
      crmPackage: {},
      setupNotification: false,
      lineConnectArr: [
        {
          id: 'line_message',
          title: '',
          channelId: null,
          channelSecret: null,
        },
        {
          id: 'line_login',
          title: '',
          channelId: null,
          channelSecret: null,
        },
      ],
      isLineConnected: false,
      isUseFacebook: false,
      linkVideoLine: '',
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'connent-setting',
    })
  },
  created() {
    this.handleFooter(true)
    Account.bzbsAnalyticTracking(
      'setting_account_page',
      'setting_account_line_connect',
      'view_line_connect',
      'on view',
    )
    this.crmPackage = Account.getCachePackage()
    this.isUseFacebook = Vue.bzbsConfig.businessRule.open.facebook
    this.linkVideoLine = Vue.bzbsConfig.businessRule.link.connectLine
    this.init()
  },
  computed: {
    fillLineChannel() {
      if (
        this.lineConnectArr[0].channelId &&
        this.lineConnectArr[0].channelSecret &&
        this.lineConnectArr[1].channelId &&
        this.lineConnectArr[1].channelSecret
      ) {
        return true
      } else {
        return false
      }
    },
  },
  mounted() {
    this.handleFooter(true)
    Promise.all([this.getHowCreate(), this.getHowConnect()]).then(() => {
      var loadingTaskHowCreate = pdf.createLoadingTask(this.howCreatePDF)
      var loadingTaskHowConnect = pdf.createLoadingTask(this.howConnectPDF)
      this.srcHowCreate = loadingTaskHowCreate
      this.srcHowConnect = loadingTaskHowConnect

      this.srcHowCreate.promise.then(pdf => {
        this.isshowpdfHowCreate = true
        this.numPagesHowCreate = pdf.numPages
      })

      this.isshowpdfHowConnect = false
      this.isShowHowConnect = false
      this.srcHowConnect.promise
        .then(pdf => {
          this.numPagesHowConnect = pdf.numPages
          this.isshowpdfHowConnect = true
        })
        .catch(() => {
          this.isShowHowConnect = false
        })
    })
  },
  methods: {
    init() {
      this.titleTooltip =
        this.lbl['line-channel-connect-webhook-tooltip-before-copy']

      this.lineConnectArr = [
        {
          id: 'line_message',
          title:
            this.lbl['account-setup-connect-line-input-line-messege-topic'],
          channelId: null,
          channelSecret: null,
        },
        {
          id: 'line_login',
          title: this.lbl['account-setup-connect-line-input-line-login-topic'],
          channelId: null,
          channelSecret: null,
        },
      ]
      Promise.all([this.getHowCreate(), this.getHowConnect()]).then(() => {
        this.getCRMPlusProfile()
      })
    },
    config: function () {
      return Vue.bzbsConfig
    },
    app_id: function () {
      return Cache.get('CRMUser').AppId
    },
    toggleHowCreate() {
      Account.bzbsAnalyticTracking(
        'setting_account_page',
        'setting_account_line_connect',
        'click_line_connect_create',
        'on click',
      )
      this.isShowHowCreate = !this.isShowHowCreate
      this.isShowHowConnect = false
    },
    toggleHowConnect() {
      Account.bzbsAnalyticTracking(
        'setting_account_page',
        'setting_account_line_connect',
        'click_line_connect_how_to',
        'on click',
      )
      this.isShowHowConnect = !this.isShowHowConnect
      this.isShowHowCreate = false
    },
    getHowCreate() {
      return new Promise(resolve => {
        this.howCreatePDF =
          Vue.bzbsConfig.bzbsBlobUrl +
          `/config/crmplus/settingmanual/howto_line_register_${this.currentLocale.toUpperCase()}.pdf` +
          `?v=` +
          new Date().getTime()
        resolve(this.howCreatePDF)
      })
    },
    getHowConnect() {
      return new Promise(resolve => {
        this.howConnectPDF =
          Vue.bzbsConfig.bzbsBlobUrl +
          `/config/crmplus/settingmanual/howto_line_connect_${this.currentLocale.toUpperCase()}.pdf` +
          `?v=` +
          new Date().getTime()
        resolve(this.howConnectPDF)
      })
    },
    getCRMPlusProfile() {
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.genDataProfile(res.data)
            this.checkAccountSettup(res.data)
            resolve(res.data)
          })
          .catch(error => {
            console.log('getCRMPlusProfile error', error)
            resolve(error)
          })
      })
    },
    checkAccountSettup(data) {
      if (data.LoginType.toLowerCase().includes('line')) {
        if ((data.LiffLanding || data.LiffId) && data.AppId) {
          this.setupNotification = false
        } else {
          this.setupNotification = true
          this.isHelper = true
        }
      } else {
        if (data.AppId) {
          this.setupNotification = false
        } else {
          this.setupNotification = true
        }
      }
    },
    genDataProfile(data) {
      console.log('Profile : ', data)
      this.loginType = data.LoginType ? data.LoginType.toLowerCase() : null
      this.appId = this.app_id() ? this.app_id() : data.AppId
      this.business_name = data.BusinessName
      this.Email = data.Email
      this.LogoUrl = data.LogoUrl
      if (
        this.checkLoginType('facebook') ||
        this.checkLoginType('email') ||
        (this.checkLoginType('device') && !this.checkLoginType('line'))
      ) {
        this.getPageList()
        this.getCampaignList()
        this.titleTooltipTh =
          this.lbl['line-channel-connect-webhook-tooltip-before-copy']
        this.titleTooltipEn =
          this.lbl['line-channel-connect-webhook-tooltip-before-copy']
        if (Vue.bzbsConfig.businessRule.webFor.PH) this.isPhilipines = true
      } else {
        if (data.LineMessageChannelId != null) {
          this.isLineConnected = true
          this.lineConnectArr[0].channelId = data.LineMessageChannelId
        }
        if (data.LineMessageSecretKey != null) {
          this.lineConnectArr[0].channelSecret = data.LineMessageSecretKey
        }
        if (data.LineLoginChannelId != null) {
          this.lineConnectArr[1].channelId = data.LineLoginChannelId
        }
        if (data.LineLoginSecretKey != null) {
          this.lineConnectArr[1].channelSecret = data.LineLoginSecretKey
        }
        this.getConnectLine()
        setTimeout(() => this.changeInput(), 300)
      }
    },
    checkLoginType(type) {
      if (this.loginType) {
        return this.loginType.toLowerCase().includes(type)
      } else {
        return false
      }
    },

    //#region Email
    changeRadio(e) {
      if (e.target.value == 2) {
        this.isPage = true
        this.isCampaign = false
        this.page = ''
        this.campaign = ''
        this.connection.campaign = []
        this.connection.page = []
        this.getCampaignList()
      } else {
        this.isPage = false
        this.isCampaign = true
        this.page = ''
        this.campaign = ''
        this.connection.campaign = []
        this.connection.page = []
        this.getPageList()
      }
    },
    getPageList() {
      let data = [
        {
          Id: 'home',
          Name: this.lbl['line-channel-select-home'],
        },
        {
          Id: 'profile',
          Name: this.lbl['line-channel-select-profile'],
        },
        {
          Id: 'myrewards',
          Name: this.lbl['line-channel-select-myrewards'],
        },
      ]

      const selected = _.map(data, e => {
        return { id: e.Id, name: e.Name }
      })
      this.connection.page = selected
      this.changePage(this.connection.page[0].id)
    },
    getCampaignList() {
      return new Promise(resolve => {
        BzbsSettingsLine.getActiveCampaignListUrl()
          .then(res => {
            console.log('getActiveCampaignListUrl', res)
            const selected = _.map(res.data, e => {
              return { id: e.ID, name: e.Name }
            })
            this.connection.campaign = selected
            resolve(res)
          })
          .catch(error => {
            console.log('getActiveCampaignListUrl error', error)
            resolve(error)
          })
      })
    },
    changePage(e) {
      console.log('changePage : ', e)
      this.urlLinkTh =
        this.config().bzbsConsumerUrl +
        '/' +
        e +
        '?app_id=' +
        this.appId +
        '&locale=th'

      //Modify by Achira on 202208021444 U-314410
      if (Vue.bzbsConfig.businessRule.webFor.PH)
        this.urlLinkTh =
          this.config().bzbsConsumerUrl +
          '/' +
          e +
          '?app_id=' +
          this.appId +
          '&locale=en'

      this.urlLinkEn =
        this.config().bzbsConsumerUrl +
        '/' +
        e +
        '?app_id=' +
        this.appId +
        '&locale=en'
    },
    changeCampaign(e) {
      console.log('changeCampaign : ', e)
      this.urlLinkTh =
        this.config().bzbsConsumerUrl +
        '/detail/' +
        e +
        '?app_id=' +
        this.appId +
        '&locale=th'

      //Modify by Achira on 202208021444 U-314410
      if (Vue.bzbsConfig.businessRule.webFor.PH)
        this.urlLinkTh =
          this.config().bzbsConsumerUrl +
          '/detail/' +
          e +
          '?app_id=' +
          this.appId +
          '&locale=en'

      this.urlLinkEn =
        this.config().bzbsConsumerUrl +
        '/detail/' +
        e +
        '?app_id=' +
        this.appId +
        '&locale=en'
    },
    copyClipboardTh() {
      var copyText = document.getElementById('LinkUrlTh')
      navigator.clipboard.writeText(copyText.value)
      this.titleTooltipTh =
        this.lbl['line-channel-connect-webhook-tooltip-after-copy']
    },
    showTooltipTh() {
      this.titleTooltipTh =
        this.lbl['line-channel-connect-webhook-tooltip-before-copy']
    },
    copyClipboardEn() {
      var copyText = document.getElementById('LinkUrlEn')
      navigator.clipboard.writeText(copyText.value)
      this.titleTooltipEn =
        this.lbl['line-channel-connect-webhook-tooltip-after-copy']
    },
    showTooltipEn() {
      this.titleTooltipEn =
        this.lbl['line-channel-connect-webhook-tooltip-before-copy']
    },
    //#endregion Email

    //#region Line
    copyClipboard() {
      var copyText = document.getElementById('WebHookUrl')
      navigator.clipboard.writeText(copyText.value)
      this.titleTooltip =
        this.lbl['line-channel-connect-webhook-tooltip-after-copy']
    },
    showTooltip() {
      this.titleTooltip =
        this.lbl['line-channel-connect-webhook-tooltip-before-copy']
    },
    changeInput() {
      if (
        this.line_message_channel_id != '' &&
        this.line_message_channel_id != null &&
        this.line_message_channel_secret != '' &&
        this.line_message_channel_secret != null &&
        this.line_login_channel_id != '' &&
        this.line_login_channel_id != null &&
        this.line_login_channel_secret != '' &&
        this.line_login_channel_secret != null
      ) {
        this.disabledsubmit = false
      } else {
        this.disabledsubmit = true
      }
    },
    handleSubmit() {
      this.$refs['my-modal-confirm'].show()
    },
    hideModal() {
      this.$refs['my-modal-confirm'].hide()
    },
    getConnectLine() {
      this.handleLoading(true)

      this.showAlertSuccessLine = false
      this.showAlertErrorLine = false
      this.showAlertSuccess = false
      this.showAlertError = false
      let params = {
        lineMessageChannelId: this.line_message_channel_id,
        lineMessageSecretKey: this.line_message_channel_secret,
        lineLoginChannelId: this.line_login_channel_id,
        lineLoginSecretKey: this.line_login_channel_secret,
      }
      return new Promise(resolve => {
        BzbsSettingsLine.getConnectLine(params)
          .then(res => {
            this.handleLoading(false)
            this.urlWebhook = res.data.webHookUrl
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('getConnectLine error', error)
            resolve(error)
          })
      })
    },
    callApiConnectLine() {
      this.handleLoading(true)
      this.showAlertSuccessLine = false
      this.showAlertErrorLine = false
      this.showAlertSuccess = false
      this.showAlertError = false

      let params = {
        lineMessageChannelId: this.lineConnectArr[0].channelId,
        lineMessageSecretKey: this.lineConnectArr[0].channelSecret,
        lineLoginChannelId: this.lineConnectArr[1].channelId,
        lineLoginSecretKey: this.lineConnectArr[1].channelSecret,
      }

      return new Promise(resolve => {
        BzbsSettingsLine.postConnectLine(params)
          .then(res => {
            this.$refs['my-modal-confirm'].hide()
            this.urlWebhook = res.data.webHookUrl
            if (res.status == 200) {
              this.showAlertSuccessLine = true
              this.getCRMPlusProfile().then(() => {
                var roleType
                if (this.crmPackage && this.crmPackage.PackageId) {
                  if (this.crmPackage.PackageId.includes('hiveplus')) {
                    roleType = 'HivePlus'
                  } else if (this.crmPackage.PackageId.includes('benefit')) {
                    roleType = 'BEEsBenefit'
                  } else {
                    roleType = 'CRMPlus'
                  }
                } else {
                  roleType = 'CRMPlus'
                }
                if (this.appId) {
                  Account.syncApplication(
                    this.appId,
                    this.business_name,
                    this.LogoUrl,
                    roleType,
                    this.Email,
                  )
                    .then(() => {
                      this.handleLoading(false)
                      resolve(res.data)
                    })
                    .catch(error => {
                      this.handleLoading(false)
                      this.showAlertError = true

                      resolve(error)
                    })
                }
              })
            }
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            this.showAlertErrorLine = true
            console.log('postConnectLine error', error)
            this.$refs['my-modal-confirm'].hide()
            resolve(error)
          })
      })
    },
    handleGenerateLiffUrl() {
      this.$refs['my-modal-confirm-generate-liff'].show()
    },
    hideModalGenerateLiffUrl() {
      this.$refs['my-modal-confirm-generate-liff'].hide()
    },
    callApiGenerateLiffUrl() {
      this.handleLoading(true)
      this.showAlertSuccessLine = false
      this.showAlertErrorLine = false
      this.showAlertSuccess = false
      this.showAlertError = false
      let params = {}
      return new Promise(resolve => {
        BzbsSettingsLine.postGenerateLiff(params)
          .then(res => {
            this.handleLoading(false)
            console.log('postGenerateLiff : ', res)
            this.$refs['my-modal-confirm-generate-liff'].hide()
            if (res.status == 200) {
              this.showAlertSuccess = true
            }
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('postGenerateLiff error', error)
            this.showAlertError = true
            this.$refs['my-modal-confirm-generate-liff'].hide()
            resolve(error)
          })
      })
    },
    //#endregion Line
  },
}
</script>

<style lang="scss" scope>
@import '../styles/accountsetup_main.scss';
.box {
  border-radius: 12px;
  background-color: #fff;
  padding: 30px;
  margin-bottom: 16px;

  .topic {
    display: flex;
    margin-bottom: 16px;
    gap: 8px;
    .name {
      color: $color-grey-42;
      font-weight: bold;
      font-size: 16px;
    }
    .desc {
      color: $text-light-gray;
      font-size: 14px;
    }
  }
  .body {
    .wrapper-radio-custom {
      width: 70%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 16px;

      .radio-main {
        flex: 1;
        min-width: 100px;
        padding: 16px;
        border-radius: 4px;
        border: solid 1px #ffffff;
        background-color: #fafafa;
        margin: 0;
        white-space: normal;
        color: $color-grey-75;

        .desc {
          margin-top: 8px;
        }
        strong {
          color: $color-grey-42;
          margin-bottom: 10px;
        }
      }
      .active {
        border: solid 1px $primary;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
      }
    }

    .input-section {
      width: 100%;
      color: $color-grey-61;
      margin-bottom: 16px;

      .name {
        font-weight: 500;
        margin-bottom: 8px;
      }
      .input-container-wrapper {
        display: flex;
        flex-flow: wrap;
        column-gap: 60px;
        row-gap: 8px;
        .input-container {
          display: flex;
          flex-flow: wrap;
          gap: 16px;
          .detail-name {
            display: flex;
            flex-flow: wrap;
            font-weight: 500;
            align-items: center;
            gap: 8px;
            .number {
              width: 32px;
              height: 32px;
              color: #ffffff;
              border-radius: 32px;
              border: 1px solid $primary;
              background-color: $primary;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .second {
          flex-grow: 1;
          .detail-input {
            flex-grow: 1;
            max-width: 350px;
          }
        }
      }
    }
    .button-secction {
      .button:disabled {
        color: #fff !important;
        background-color: #c4c4c4 !important;
        border-color: #c4c4c4 !important;
      }
    }
    .connect-success {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border: 1px solid $color-active;
      background-color: $color-active;
      color: #ffffff;
    }
    .noted-wrapper {
      width: 100%;
      text-align: -webkit-center;
      .noted {
        width: 60%;
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 8px;
        padding: 16px;
        border-radius: 6px;
        border: solid 1px #f0f0f0;
        background-color: $color-grey-fa;
        color: $color-grey-75;
      }

      .webhook-result-container {
        display: flex;
      }
    }
    .gen-link-option-container {
      .select-link-connect-radio-container {
        margin: 8px auto;
      }
      .selected-link-connect-option {
        .field-item {
          .select-box {
            width: 50%;
            min-width: 100px;
          }
        }
      }
    }
    .display-link-container {
      display: flex;
      flex-flow: wrap;
      gap: 24px;

      .thai-link-container,
      .en-link-container {
        flex: 1;

        .page-languages {
          color: $color-grey-42;
          font-weight: 600;
          .flag-languages {
            width: 40px;
            height: auto;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.helper-btn {
  cursor: pointer;
  position: fixed;
  bottom: 64px;
  right: 24px;
  color: #b0b0b0;
  border-radius: 30px;
  background: $color-white-f5;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  padding: 16px 18px;
  justify-content: center;
  align-items: center;
}
.wrap-AccountSetup {
  .line {
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
    margin: 16px auto;
  }
}
.accordion {
  .accordion-header {
    display: flex;
    justify-content: space-between;

    .arrow {
      transition: all 0.3s ease-in-out;
    }
    .active {
      transform: rotate(180deg);
      transition: all 0.3s ease-in-out;
    }
  }
  .accordion-body {
    max-height: 0;
    transition: max-height 0.3s ease-out;
    overflow: hidden;
  }
  .how-create-accordion-active,
  .how-connect-accordion-active {
    max-height: unset;
    transition: max-height 0.3s ease-in;
  }
}
.modal-body {
  padding: 1.5rem 2rem;

  .modal-base-head {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.15px;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
    .icoTitle {
      width: 44px;
      height: 44px;
      padding: 5px 10px 10px 10px;
      border-radius: 4px;
      background-color: rgba(76, 175, 80, 0.1);
    }
  }

  .modal-base-body {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.15px;
    text-align: left;
    color: #616161;
  }
}
.modal-base-footer {
  text-align: end;
  .btn-warning {
    color: #ffffff;
    background-color: #ffc107;
    border-color: #ffc107;

    &:hover {
      color: #ffffff;
      background-color: #ecb100;
      border-color: #ecb100;
    }
    &.cancel {
      color: #ffc107;
      background-color: #fff;
      border-color: #ffc107;
      &:hover {
        color: #ffffff;
        background-color: #ecb100;
        border-color: #ecb100;
      }
    }
  }
}
.dot-notification {
  position: absolute;
  right: -8px;
  top: 0;
}
</style>
